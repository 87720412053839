import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import GridRedButton from 'components/GridRedButton'

export default function SolutionsTemplate({ data }) {
  const page = data.wpPage

  return (
    <Layout
      bodyClassName='page-template page-template-page-builder page-builder page page-id-136 solutions app-data index-data singular-data page-data page-136-data page-solutions-data page-builder-data'
      page={page}
    >
      {/* <Banner content={banner} isRecenter={true} /> */}
      <GridRedButton content={page.template.solutions.grid} />
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    wpPage(slug: { eq: $slug }) {
      title
      ...SeoPage
      ...BannerPage
      ...RelatedBlockPage
      ...ContactBlockPage
      template {
        templateName
        ... on WpTemplate_Solutions {
          solutions {
            grid {
              icon {
                ...GatsbyImage
              }
              title
              description
              pageLink {
                ...PageLinkPageInner
                ...PageLinkPostInner
              }
            }
          }
        }
      }
    }
  }
`
