import React from 'react'
import PageLink from 'components/PageLink'
import ImageWrap from 'components/ImageWrap'
import { strings } from 'js/strings'

export default function GridRedButton({ content }) {
  let rows = []
  for (let i = 0; i < content.length; i += 2) {
    rows.push(content.slice(i, i + 2))
  }

  return (
    <>
      {rows.map((row, i) => (
        <GridRow row={row} key={i} />
      ))}
    </>
  )
}

function GridRow({ row }) {
  return (
    <section className='multivariate-grid multivariate-grid--horizontal-clip multivariate-grid--2 tiles-icon-cta tiles-icon-cta--pull-up'>
      <div className='tiles-icon-cta__container multivariate-grid__container'>
        <div className='tiles-icon-cta__row multivariate-grid__row'>
          {row.map((item, i) => (
            <GridItem item={item} key={i} />
          ))}
        </div>
      </div>
    </section>
  )
}

function GridItem({ item }) {
  const { icon, title, description, pageLink } = item
  return (
    <div className='tiles-icon-cta__column multivariate-grid__column'>
      <PageLink
        pageLink={pageLink}
        className='content-image-card content-image-card--pointer'
      >
        <div className='content-image-card__icon-wrap'>
          <ImageWrap image={icon} />
        </div>
        <h4
          className='content-image-card__title'
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div className='content-image-card__content'>
          <div className='content-image-card__excerpt'>
            <p>{description}</p>
          </div>
          {pageLink && (
            <div className='content-image-card__cta'>{strings.learnMore}</div>
          )}
        </div>
      </PageLink>
    </div>
  )
}
